$main_font:'Open Sans', sans-serif;

$theme_color1:#009dc0;
$wheme_color2:#f1f5f7;

$font_color:#343942;
$header_color:#003a55;
$accent_color:#afdb29;
$accent_color2:#009cc9;


$white:#ffffff;

$c_content_color:#2e2e2d;
$footer_color:#3b3b39;

@mixin fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin anim {
    transition: 0.3s all ease;
}

body{
    font-family: $main_font;
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin: 0;
    }
    ul{
        list-style: none;
        padding: 0;
    }
    ul,ol{
        li{
            &:before {
                content: "\200B"; /* spacja o zerowej szerokości */
            }
        }
    }
    a{
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }
}

header.header{
    button{
        &:not(.menu_btn){
            padding: 0;
            width: auto !important;
        }
    }
    img{
        height: 20px;
        width: auto;
    }
}

.lang_box{
    > ul{
        > li{
            display: inline-block;
            vertical-align: top;
            &:last-child{
                &:after{
                    display: none;
                }
            }
            &:after{
                content: '/';
                margin: 0 5px;
            }
            a{
                font-size: 12.5px;
                line-height: 12.5px;
                color: $white;
            }
        }
    }
}

.sub_header{
    padding: 0 50px;
    @media only screen and (max-width: 1200px) {
        padding: 0;
    }
}

div.default_font{
    font-size: 14px;
    line-height: 32px;
    color: $font_color;
    @media only screen and (max-width: 1500px) {
        font-size: 12px;
        line-height: 30px;
    }
    //h1{
    //    font-size: 60px;
    //    line-height: 80px;
    //    @media only screen and (max-width: 1500px) {
    //        font-size: 52px;
    //        line-height: 72px;
    //    }
    //}
    h1,h2{
        font-size: 42px;
        line-height: 62px;
        @media only screen and (max-width: 1500px) {
            font-size: 37px;
            line-height: 50px;
        }
        @media only screen and (max-width: 1200px) {
            font-size: 27px;
            line-height: 40px;
        }
    }
    h3{
        font-size: 37px;
        line-height: 50px;
        @media only screen and (max-width: 1500px) {
            font-size: 24px;
            line-height: 37px;
        }
    }
    h4{
        font-size: 24px;
        line-height: 37px;
        @media only screen and (max-width: 1500px) {
            font-size: 22px;
            line-height: 35px;
        }
    }
    h5{
        font-size: 22px;
        line-height: 35px;
        @media only screen and (max-width: 1500px) {
            font-size: 18px;
            line-height: 31px;
        }
    }
    h6{
        font-size: 18px;
        line-height: 31px;
        @media only screen and (max-width: 1500px) {
            font-size: 16px;
            line-height: 34px;
        }
    }
    ul{
        list-style: disc;
        padding-left: 18px;
    }
    ol{
        padding-left: 18px;
    }
    a{
        text-decoration: underline;
        color: #343942;
        &:hover{
            color: $accent_color;
        }
    }
    strong{
        font-weight: 700;
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        //margin-bottom: 30px;
        @media only screen and (max-width: 1500px) {
            //margin-bottom: 15px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.more_btn{
    display: inline-block;
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $header_color;
    background: $accent_color;
    border-radius: 50px;
    padding: 24px 80px;
    @media only screen and (max-width: 1500px) {
        font-size: 14px;
        padding: 24px 60px;
    }
    &.more_small{
        font-size: 12.5px;
        line-height: 12.5px;
        padding: 16px 41px;
        &.blue{
            background: $theme_color1;
            color: $white;
        }
    }
}

.grey_button{
    display: inline-block;
    font-size: 12.5px;
    line-height: 12.5px;
    text-transform: uppercase;
    padding: 18px 45px;
    letter-spacing: 2px;
    color: $header_color;
    background: #dbe2e5;
    border-radius: 50px;
    font-weight: 700;
}

.delete_button{
    display: inline-block;
    font-size: 12.5px;
    line-height: 12.5px;
    text-transform: uppercase;
    padding: 18px 45px;
    letter-spacing: 1px;
    color: $header_color;
    background: #dbe2e5;
    border-radius: 50px;
    font-weight: 700;
    @include anim;
    @media only screen and (max-width: 1500px) {
        padding:15px 18px;
        font-size: 10.5px;
        line-height: 10.5px;
    }
    &:hover{
        background: #BD2826;
        color: #ffffff;
    }
}
.vertical_align{
    @include fill;
    > div{
        display: table;
        height: 100%;
        width: 100%;
        > div{
            display: table-cell;
            vertical-align: middle;
            height: 100%;
        }
    }
}

.page{
    position: relative;
    z-index: 2;
    overflow: hidden;
    &.contrast_page{
        .your_register{
            background-image: none !important;
        }
        .top{
            background-image: none ;
        }
        .doc_list ul li a{
            &:hover{
                background-color: transparent;
                color: red;
            }
        }
        .gm-ui-hover-effect{
            background: $white !important;
            opacity: 1;
        }
        *{
            &:focus{
                outline: 2px dotted red !important;
            }
        }
        a{
            &:focus{
                outline-offset: 2px;
            }
        }

        .news_list{
            a{
                &:focus{
                    outline: none !important;
                    article{
                        outline: 2px dotted red !important;
                    }
                }
            }
        }
        a.login{
            background: black !important;
            border: 1px solid yellow;
            color: yellow !important;
        }
        p.login{
            background: red !important;
            color: yellow !important;
        }
        //*:focus{
        //
        //    outline: 2px solid red !important;
        //}
        .glyphicon{
            color: yellow !important;
        }
        .dz-message{
            color: yellow;
        }
        .default_font{
            &:after{
                display: none !important;
            }
        }
        section.contact{
            &:after{
                display: none !important;
            }
        }
        .arr{
            background-image: none !important;
        }
        div.slider_dots {
            > div{
                > button{
                    background: yellow !important;
                    position: relative;
                    &.on{
                        background: red !important;
                        &:after{
                            content: '';
                            top: -10px;
                            left: -10px;
                            right: -10px;
                            bottom: -10px;
                            border: 1px solid red;
                            position: absolute;
                        }
                    }
                }
            }
        }
        h1,h2,h3,h4,h5,h6,ul,li,p, label{
            color: yellow !important;
            background-color: #000000 !important;
            strong{
                color: inherit !important;
            }
        }
        .chosen-single{
            background: black !important;
            span{
                color: yellow !important;
            }
        }
        .previewsContainer li{
            border: 1px dashed red !important;
        }
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: red !important;
          }
          ::-moz-placeholder { /* Firefox 19+ */
            color: red !important;
          }
          :-ms-input-placeholder { /* IE 10+ */
            color: red !important;
          }
          :-moz-placeholder { /* Firefox 18- */
            color: red !important;
          }
        a{
            color: red;
        }
        div, section, article, footer{
            background-color: #000000 !important;
            border: none !important;
            //background-image: none !important;
        }
        .home_slides{
            .cover{
                display: none;
            }
        }
        .about{
            background-image: none !important;
        }
        section.login_register{
            &:after{
                display: none !important;
            }
        }
        input{
            background: black !important;
            color: yellow !important;
            border: 1px solid yellow !important;
        }
        header.header{
            .menu_btn {
                > div{
                    background: $white !important;
                }
            }

        }
        header.header {
            .menu_box {
                nav.menu{
                    ul {
                        > li {
                            border-bottom: none !important;
                            a{
                                color: red !important;
                            }
                        }
                    }
                }
            }
            nav.menu{
                background:  #000000 !important;
            }
        }
        .more_btn{
            background: black !important;
            color: red !important;
        }
        /*.news{
            div.news_list {
                > ul {
                    > li {
                        article{
                            background: #2e2e2d;
                            border: none;
                        }
                    }
                }
            }
        }
        h1,h2,h3,h4,h5,h6,p{
            color: yellow !important;
        }
        .footer a, .to_up{
            color: $white;
        }
        *:focus{

            outline: 2px solid red;
        }
        section.contact {
            div.desc{
                color: yellow;
            }
            div.support{
                div.default_font{
                    color: yellow;
                }
            }
        }
        header.header {
            .menu_box {
                nav.menu{
                    background: yellow;
                    ul {
                        > li {
                            a{
                                color: red;
                            }
                        }
                    }
                }
            }
        }
        section.slider_movie {
            div.table_box {
                > div div.desc{
                    background: $c_content_color;
                }
            }
            div.slider_nav {
                > div {
                    div.table_box {
                        > div.arr{
                            color: yellow;
                        }
                        > div.slider_dots {
                            > ul {
                                > li.on{
                                    background: red;
                                }
                            }
                        }
                    }
                }
            }
        }
        form {
            .remind_pass{
                color: yellow;
            }
            div.holder {
                &.rules{
                    div.desc{
                        color: yellow;
                    }
                }
                label{
                    color: yellow;
                }
            }
        }
        main.content, section{
            background: $c_content_color;
        }
        .cover{
            display: none;
        }
        div.default_font{
            color: yellow;
        }
        header.section_header {
            div.default_font{
                &.grey{
                    h1,h2,h3,h4,h5,h6{
                        background: $c_content_color;
                    }
                }
                &.white{
                    h1,h2,h3,h4,h5,h6{
                        background: $c_content_color;
                    }
                }
                color: red;
                strong{
                    color: yellow;
                }
            }
            div.header_desc{
                color: yellow;
            }
        }
        section.faq {
            div.faq_list {
                > ul {
                    > li {
                        div.question {
                            > div.desc {
                                > div.default_font{
                                    h3{
                                        color: red;
                                    }
                                }
                            }
                        }
                        .answer{
                            background: $c_content_color;
                           }
                    }
                }
            }
        }

        .footer{
            background: $font_color;
        }
        main.content{
            &.bg{
                div.container{
                    background: $c_content_color;
                }
            }
        }
        section.login_register:after, section.contact:after{
            display: none;
        }
        section.after_login_view {
            &.view2 {
                .overall_progress{
                    background: transparent;
                    div.progress_bar_small {
                        div.progress_count{
                            color: red;
                            strong,span{
                                color: yellow;
                            }
                        }
                        > div.bar{
                            background: yellow;
                            div{
                                background: red;
                            }
                        }
                    }
                    div.steps{
                        &.default_font{
                            color: yellow;
                        }
                    }
                }
            }
            header.after_login_header {
                h2{
                    color: red;
                    strong,span{
                        color:yellow;
                    }
                }
            }
            .after_login_view_content {
                div.your_data{
                    background: transparent;
                }
                header h3{
                    color: red;
                }
                div.forms_list {
                    div.form_box {
                        div.f_top {
                            > ul {
                                > li {
                                    > div.desc {
                                        color: red;
                                        h4{
                                            color: yellow;
                                        }
                                    }
                                }
                            }
                        }
                        div.f_bottom{
                            background: $font_color;
                            ul{
                                li{
                                    color: red !important;
                                    &.bottom_right {
                                        div.progress_bar_small {
                                            > div.bar {
                                                background: yellow;
                                                > div {
                                                    background: red;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            span{
                                color: yellow !important;
                            }
                        }
                    }
                }
            }

        }

        section.step_form {
            .form_summary{
                background: #0d0d0c;
                div.step_progress {
                    > div.step_name {
                        > div.desc {
                            > div{
                                color: red;
                            }
                        }
                    }
                    > .progress1 {
                        div{
                            div.progress_bar{
                                background: yellow;
                                div{
                                    background: red;
                                }
                            }
                        }
                        .progress_count{
                            color: yellow;
                            strong{
                                color: red;
                            }
                        }
                    }
                }
            }
            header.step_form_header{
                color: yellow;
                h1{
                    color: yellow;
                    strong, span{
                        color: red;
                    }
                }
            }
            form {
                div.step_form_holder {
                    > div.desc {
                        > div {
                            header {
                                h2{
                                    color: red;
                                }
                            }
                        }
                    }
                }
            }
        }*/
    }
    &.subpage{
        header.header{
            border: none;
            padding: 0;
        }
    }


    &.small{
        header.header {
            .menu_box {
                nav.menu {
                    ul {
                        > li {
                            a{
                                font-size: 9px;
                                line-height: 9px;
                            }
                        }
                    }
                }
            }
        }
        section.step_form {
            div.form_summary {
                div.step_progress{
                    > div.step_name {
                        > div.desc {
                            > div {
                                h2{
                                    font-size: 13px;
                                    line-height: 23px;
                                }
                            }
                        }
                    }
                }
            }
        }
        form {
            .text_box {
                .textarea{
                    font-size: 11px;
                }
            }
        }
        form {
            div.holder {
                input.default_submit{
                   font-size: 14px;
                   line-height: 14px;
                }
            }
        }
        section.step_form {
            form {
                div.step_form_holder {
                    > div.desc {
                        > div {
                            header {
                                h2{
                                    font-size: 13px;
                                    line-height: 23px;
                                }
                            }
                        }
                    }
                }
            }
        }
        header.step_form_header{
            margin-top: -60px;
            font-size: 33.5px;
            font-weight: 300;
            color: $font_color;
            padding-bottom: 20px;
            border-bottom: 2px solid #e0e7eb;
            margin-bottom: 30px;
            @media only screen and (max-width: 1500px) {
                font-size: 28.5px;
                margin-top: -45px;
            }
            @media only screen and (max-width: 1200px) {
                font-size: 22.5px;
                margin-top: -30px;
            }
            @media only screen and (max-width: 991px) {
                text-align: center;
                h1{
                    margin-bottom: 15px;
                }
            }
            &.header_summary{
                margin-top: 0;
                border-bottom: none;
            }
            h1,p{
                font-size: inherit;
                font-weight: inherit;
            }
            h1{
                padding-top: 10px;
                color: $header_color;
                span{
                    color: $accent_color2;
                }
            }
        }
        footer.footer{
            font-size: 11px;
        }
        a, p.to_up, .grey_button, header.header a.logout, header.header a.return{

            font-size: 8.5px;
            cursor: pointer;
        }
        section.after_login_view{
            .after_login_view_content{
                header{
                    h3{
                       font-size: 21px;
                       line-height: 32px;
                    }
                }
            }
        }
        .default_font{
            font-size: 10px;
            line-height: 28px;
            color: $font_color;
            @media only screen and (max-width: 1500px) {
                font-size: 8px;
                line-height: 26px;
            }
            h1{
                font-size: 56px;
                line-height: 76px;
                @media only screen and (max-width: 1500px) {
                    font-size:48px;
                    line-height: 68px;
                }
            }
            h2{
                font-size: 48px;
                line-height: 68px;
                @media only screen and (max-width: 1500px) {
                    font-size: 33px;
                    line-height: 46px;
                }
            }
            h3{
                font-size: 33px;
                line-height: 46px;
                @media only screen and (max-width: 1500px) {
                    font-size: 20px;
                    line-height: 33px;
                }
            }
            h4{
                font-size: 20px;
                line-height: 33px;
                @media only screen and (max-width: 1500px) {
                    font-size: 18px;
                    line-height: 31px;
                }
            }
            h5{
                font-size: 18px;
                line-height: 31px;
                @media only screen and (max-width: 1500px) {
                    font-size: 14px;
                    line-height: 27px;
                }
            }
            h6{
                font-size: 14px;
                line-height: 27px;
                @media only screen and (max-width: 1500px) {
                    font-size: 12px;
                    line-height: 30px;
                }
            }
        }
    }
    &.large{
        header.header {
            .menu_box {
                nav.menu {
                    ul {
                        > li {
                            a{
                                font-size: 17px;
                                line-height: 17px;
                            }
                        }
                    }
                }
            }
        }
        section.step_form {
            div.form_summary {
                div.step_progress{
                    > div.step_name {
                        > div.desc {
                            > div {
                                h2{
                                    font-size: 19px;
                                    line-height: 29px;
                                }
                            }
                        }
                    }
                }
            }
        }
        form {
            .text_box {
                .textarea{
                    font-size: 17px;
                }
            }
        }
        form {
            div.holder {
                input.default_submit{
                   font-size: 20px;
                   line-height: 20px;
                }
            }
        }
        section.step_form {
            form {
                div.step_form_holder {
                    > div.desc {
                        > div {
                            header {
                                h2{
                                    font-size: 19px;
                                    line-height: 29px;
                                }
                            }
                        }
                    }
                }
            }
        }
        header.step_form_header{
            margin-top: -60px;
            font-size: 39.5px;
            font-weight: 300;
            color: $font_color;
            padding-bottom: 20px;
            border-bottom: 2px solid #e0e7eb;
            margin-bottom: 30px;
            @media only screen and (max-width: 1500px) {
                font-size: 34.5px;
                margin-top: -45px;
            }
            @media only screen and (max-width: 1200px) {
                font-size: 28.5px;
                margin-top: -30px;
            }
            @media only screen and (max-width: 991px) {
                text-align: center;
                h1{
                    margin-bottom: 15px;
                }
            }
            &.header_summary{
                margin-top: 0;
                border-bottom: none;
            }
            h1,p{
                font-size: inherit;
                font-weight: inherit;
            }
            h1{
                padding-top: 10px;
                color: $header_color;
                span{
                    color: $accent_color2;
                }
            }
        }
        footer.footer{
            font-size: 17px;
        }
        a, p.to_up, .grey_button, header.header a.logout, header.header a.return{

            font-size: 14.5px;
            cursor: pointer;
        }
        section.after_login_view{
            .after_login_view_content{
                header{
                    h3{
                       font-size: 27px;
                       line-height: 38px;
                    }
                }
            }
        }
        .default_font{
            font-size: 16px;
            line-height: 34px;
            color: $font_color;
            @media only screen and (max-width: 1500px) {
                font-size: 14px;
                line-height: 32px;
            }
            h1{
                font-size: 62px;
                line-height: 82x;
                @media only screen and (max-width: 1500px) {
                    font-size: 54px;
                    line-height: 74px;
                }
            }
            h2{
                font-size: 54px;
                line-height: 74px;
                @media only screen and (max-width: 1500px) {
                    font-size: 39px;
                    line-height: 52px;
                }
            }
            h3{
                font-size: 39px;
                line-height: 52px;
                @media only screen and (max-width: 1500px) {
                    font-size: 26px;
                    line-height: 39px;
                }
            }
            h4{
                font-size: 26px;
                line-height: 39px;
                @media only screen and (max-width: 1500px) {
                    font-size: 24px;
                    line-height: 37px;
                }
            }
            h5{
                font-size: 24px;
                line-height: 36px;
                @media only screen and (max-width: 1500px) {
                    font-size: 20px;
                    line-height: 33px;
                }
            }
            h6{
                font-size: 20px;
                line-height: 33px;
                @media only screen and (max-width: 1500px) {
                    font-size: 18px;
                    line-height: 36px;
                }
            }
        }
    }

}

section.slider{
    height: 100vh;
    width: 100%;
    position: absolute;
    div{
        @include fill;
        background-color: #0090b7;
    }
    //> ul{
    //    @include fill;
    //    background-color: #0090b7;
    //
    //}
}

.hero_image{
    background-color: $theme_color1;
    @include fill;
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    &.subpage{

        height: 390px;
        background-position: center bottom;
        background-size: auto;
    }
    //&.opacity{
    //    opacity: 0.3;
    //}
}

.Interactive_logo{
    position: relative;
    height: 90vh;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('./../img/nakladka.png');
    @media only screen and (max-width: 991px) {
        background-color: $theme_color1;
    }
    .logo_box{
        width: 56%;
        margin: 0 auto;
        @media only screen and (max-width: 991px) {
            width: 100%;
            padding: 0 15px;
        }
        img, svg{
            max-width: 100%;
            height: auto;
        }
        img.logo1{
            margin-top: 22%;
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
        img.logo2{
            display: none;
            @media only screen and (max-width: 991px) {
                display: block;
            }
        }
        .svg_map{
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
    }
}

.inline_element{
    display: inline-block;
    //vertical-align: top;
    vertical-align: middle;
    margin-right: 15px;
    line-height: 0;
    &:last-child{
        margin-right: 0;
    }
}

header.header{
    padding: 27px 0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    color: $white;
    font-size: 12.5px;
    line-height: 12.5px;
    font-weight: 700;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    text-transform: uppercase;
    //*:focus{
    //
    //    outline: 2px solid $accent_color;
    //}
    @media only screen and (max-width: 991px) {
        text-align: center;
        position: relative;
    }
    @media only screen and (max-width: 600px) {
        font-size: 10px;
        line-height: 10px;
        text-align: center;
        padding: 14px 0;
    }
    p,a{
        font-weight: inherit;
        display: inline-block;
        //vertical-align: top;
        vertical-align: middle;
        @media only screen and (max-width: 991px) {
            margin: 5px 0;
        }
        &.login{
            color: $header_color;
            padding: 15px 25px;
            border-radius: 50px;
            position: relative;
            background-color: $accent_color;
            @include anim;
            z-index: 2;
        }
        &.register{
            color: $white;
            text-decoration: none;
        }
    }
    p{
        padding-top: 14px;
        @media only screen and (max-width: 600px) {
            padding-top: 0;
            padding-bottom: 14px;
        }
    }
    .col-md-6, .col-md-7, .col-md-8, .col-md-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-5, .col-lg-4{
        > ul{
            text-align: right;
            @media only screen and (max-width: 1700px) {
                //padding-right: 50px;
            }
            @media only screen and (max-width: 991px) {
                text-align: center;
                padding-right: 0;
            }
            > li{
                display: inline-block;
                vertical-align: middle;
                margin-right: 15px;
                @media only screen and (max-width: 1200px) {
                    margin-right: 10px;
                }
                button.search_btn{
                    width: 48px;
                    border: none;
                    background: transparent;
                    img{
                        max-width: 100%;
                    }
                }
                &.contrast{
                    cursor: pointer;
                    //@media only screen and (max-width: 991px) {
                    //    display: none;
                    //}
                }
                &.fonts{
                    @media only screen and (max-width: 991px) {
                        display: none;
                    }
                    > ul{
                        > li{
                            margin-right: 10px;
                            display: inline-block;
                            vertical-align: top;
                            cursor: pointer;
                            font-size: 15px;
                            @media only screen and (max-width: 1200px) {
                                margin-right: 5px;
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    a.return{
        &:before{
            content:'<';
            margin-right: 5px;
        }
    }
    a.logout{
        &:after{
            content:'>';
            margin-left: 5px;
        }
    }
    a.return, a.logout{
        font-size: 12.5px;
        line-height: 12.5px;
        text-transform: uppercase;
        color: $white;
        font-weight: 700;
        display: inline-block;
        margin-left: 50px;
        border-bottom: 1px solid rgba(255,255,255,0.2);
        padding: 27px 0;
    }
    a.logout{
        margin-left: 0;
        padding-right:  50px;
        @media only screen and (max-width: 991px) {
            padding-right: 0;
        }
    }
    @media only screen and (max-width: 991px) {
        a.return{
            width: 100%;
            margin-left: 0;
            padding: 14px 0;
            text-align: center;
        }
    }
    .social_btn{
        &.fb{
            cursor: pointer;
            width: 42px;
            height: 0;
            padding-bottom: 42px;
            background-color: #3b5998;
            border-radius: 50%;
            position: relative;
            border: none;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("./../img/fb.svg");
            background-size: 30px;
        }
    }
    .menu_btn{
        cursor: pointer;
        width: 42px;
        height: 0;
        padding-bottom: 42px;
        background: $accent_color;
        border-radius: 50%;
        position: relative;
        border: none;
        > span{
            background: $white;
            height: 3px;
            position: absolute;
            left: 20%;
            width: 60%;
            display: block;
            &:nth-child(1){
                top: 30%;
            }
            &:nth-child(2){
                top: 50%;
            }
            &:nth-child(3){
                top:70%;
            }
        }
    }
    .menu_box{
        width: 100%;
        position: relative;
        nav.menu{
            visibility: hidden;
            opacity: 0;
            width: 244px;
            background:$white;
            position: absolute;
            right: 0;
            border-radius: 15px;
            margin-top: 0;
            @include anim;
            top: 0;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            ul{
                > li{
                    padding: 15px;
                    border-bottom: 1px solid $accent_color;
                    width: 100%;
                    display: block;
                    &:last-child{
                        border-bottom: none;
                    }
                    a{
                        font-size: 13px;
                        line-height: 13px;
                        text-transform: uppercase;
                        display: inline-block;
                        color: $header_color;
                        @include anim;
                        &:hover{
                            padding-left: 5px;
                            color: $accent_color;
                        }
                    }
                }
            }
        }
    }
}

.logotypes{
    @media only screen and (max-width: 991px) {
        text-align: center;
        width: 100%;
    }
    ul{
        @media only screen and (max-width: 1200px) {
            padding-top: 10px;
        }
        @media only screen and (max-width: 991px) {
            padding-top: 0;
        }
        li{
            //height: 50px;
            width: auto;
            margin-right: 20px;
            display: inline-block;
            vertical-align: middle;
            @media only screen and (max-width: 1200px) {
                margin-right: 10px;
            }
            img{
                height: 100%;
                width: auto;
                @media only screen and (max-width: 1500px) {
                    height: 40px;
                    width: auto;
                }
                @media only screen and (max-width: 1200px) {
                    height: 26px;
                    width: auto;
                }
                @media only screen and (max-width: 991px) {
                    height: 25px;
                    width: auto;
                }
            }
            &:last-child{
                margin-right: 0;
            }
        }
    }
}

header.section_header{
    .date{
        font-size: 15px;
        color: #9da3ae;
        margin-bottom: 15px;
    }
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    &.line{
        position: relative;
        z-index: 5;
        div.default_font{
            h1,h2,h3,h4,h5,h6{
                padding-right: 30px;
                @media only screen and (max-width: 991px) {
                    padding-right: 15px;
                    padding-left: 15px;
                }
            }
            &:after{
                content: '';
                width: 100%;
                height: 2px;
                background:#e0e7eb;
                position: absolute;
                top: 50%;
                left: 0;
            }
        }
    }
    div.default_font{
        margin-bottom: 60px;
        color: $header_color;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 30px;
        }
        h1,h2,h3,h4,h5,h6{
            font-weight: 700;
            position: relative;
            display: inline-block;
            z-index: 3;
            strong{
                color: $accent_color;
            }
        }
        &.white{
            h1,h2,h3,h4,h5,h6{
                background: $white;
            }
        }
        &.grey{
            h1,h2,h3,h4,h5,h6{
                background: #f8fafb;
                @media only screen and (max-width: 991px) {
                    background: $white;
                }
            }
        }
    }
    div.header_desc{
        color: $font_color;
        font-size: 22px;
        line-height: 36px;
        font-weight: 300;
        margin-bottom: 30px;
        @media only screen and (max-width: 1500px) {
            font-size: 18px;
            line-height: 31px;
            margin-bottom: 15px;
        }
        p{
            font-weight: inherit;
        }
    }
}

main.content{
    margin-top:-1px;
    background-color: $white;
    min-height: 50vh;
    &.bg{
        background-position: center top 20%;
        background-size: 50% auto;
        background-image: url("./../img/all_icons2.png");
        .container{
            background:$white;
        }
    }
}

.about{
    padding-top: 35px;
    //background: url('./../img/about_bg.jpg') center top 50px no-repeat;
    margin-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 50px;
    }
    div{
        &.left_side{
            .text-right{
                padding-top: 30px;
            }
            padding-right: 120px;
            @media only screen and (max-width: 1500px) {
                padding-right: 60px;
            }
            @media only screen and (max-width: 991px) {
                text-align: center;
                padding-right: 0;
                margin-bottom: 35px;
            }
        }
    }
    .home_slides{
        width: 100%;
        height: 0;
        padding-bottom: 97%;
        position: relative;
         >ul{
            @include fill;
             li{
                @include fill;
                background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
             }
         }
         div.cover{
            @include fill;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-image: url('./../img/nakladka_foto.png');
        }
    }
    .image{
        width: 100%;
        height: 0;
        padding-bottom: 97%;
        background-size: cover;
        position: relative;

    }
}

section.your_register{
    padding-top: 90px;
    padding-bottom: 100px;
    background-position: center top 90px;
    background-repeat: repeat-x;
    background-size: 100% auto;
    background-image: url('./../img/all_icons.png');
    @media only screen and (max-width: 991px) {
        background-size: auto;
        background-position: center center;
        padding-top: 30px;
        padding-bottom: 50px;
    }
    div{
        &.desc{
            ul{
                li{
                    display: inline-block;
                    vertical-align: top;
                    @media only screen and (max-width: 991px) {
                        display: block;
                    }
                    &:first-child{
                        width: 125px;
                        height: 0;
                        padding-bottom: 119px;
                        border: 3px solid $accent_color;
                        border-radius: 50%;
                        position: relative;
                        overflow: hidden;
                        @media only screen and (max-width: 991px) {
                            margin-left: auto;
                            margin-right: auto;
                        }
                        .icon{
                            background-color: $white;
                            @include fill;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-image: url('./../img/like.png');
                        }
                    }
                    &:last-child{
                        width: calc(100% - 125px);
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            text-align: center;
                        }
                        div.default_font{
                            @media only screen and (max-width: 991px) {
                                padding-left: 0;
                            }
                            padding-top: 35px;
                            padding-left: 40px;
                            @media only screen and (max-width: 1500px) {
                                padding-top: 23px;
                            }
                        }
                    }
                }
            }
        }
        &.go_form{
            padding-top: 35px;
            text-align: center;
            &.no_padding{
                padding-top: 0;
            }
        }
    }
}

section.slider_movie{
    position: relative;
    height: 0;
    padding-bottom: 33%;
    background: #f1f5f7;
    width: 100%;
    @media only screen and (max-width: 991px) {
        height: 100vh;
    }
    > .slides{
        @include fill;
        > div{
            @include fill;
            display: block;
        }
    }
    div.table_box{
        display: table;
        width: 100%;
        height: 100%;
        @media only screen and (max-width: 991px) {
            display: block;
        }
        > div{

            display: table-cell;
            vertical-align: middle;
            height: 100%;
            position: relative;
            &:first-child{
                width: 65%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
            }
            &::last-child{
                width: 35%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
                display: block;
                height: 50%;
                position: absolute;
                left: 0;
                &:first-child{
                    top:0;
                }
                &:last-child{
                    top: 50%;
                    display: table;
                    > div.desc{
                        display: table-cell;
                        vertical-align: middle;
                        height: 100%;
                        text-align: center;
                        > div{
                            padding-left: 0;
                        }
                    }
                }
            }
            div{
                &.movie{
                    //@include fill;
                    height: 100%;
                    width: 100%;
                    > iframe{
                        @include fill;
                    }
                }
                &.desc{
                    //@include fill;
                    background: #f1f5f7;
                    > div{
                        //padding-top: 80px;
                        padding-left: 70px;
                        @media only screen and (max-width: 991px) {
                            padding-left: 15px !important;
                            padding-right: 15px !important;
                        }
                        header.section_header{
                            div.default_font{
                                margin-bottom: 40px;
                                h2{
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    div.slider_nav{
        width: 35%;
        position: absolute;
        right: 0;
        bottom: 0;
        border-top: 1px solid #d7dde1;
        z-index: 5;
        @media only screen and (max-width: 991px) {
            width: 100%;
        }
        > div{
            padding-left: 70px;
            @media only screen and (max-width: 991px) {
                padding-left: 15px;
                padding-right: 15px !important;
            }
            div.table_box{
                height: 80px;
                @media only screen and (max-width: 991px) {
                    display: table;
                    width: 100%;
                }
                > div{
                    @media only screen and (max-width: 991px) {
                        height: 100%;
                        display: table-cell;
                        vertical-align: middle;
                        position: relative;
                        top: 0;
                    }
                    &.arr{
                        width: 33%;
                        font-size: 12.5px;
                        font-weight: 600;
                        color: #899ea6;
                        background-repeat: no-repeat;
                        cursor: pointer;
                        button{
                            border: none;
                            background: transparent;
                        }
                        @media only screen and (max-width: 991px) {
                            font-size: 0;
                            width: 20px;
                        }
                        &.arr_left{
                            padding-left: 25px;
                            background-position: left center;
                            background-image: url('./../img/sl_arr_left.png');
                        }
                        &.arr_right{
                            padding-right: 25px;
                            background-position: right center;
                            background-image: url('./../img/sl_arr_right.png');
                        }
                    }
                    &.slider_dots{
                        width:33%;
                        text-align: center;
                        @media only screen and (max-width: 991px) {
                            width: calc(100% - 40px);
                        }
                        > div{
                            > button{
                                border: none;
                                display: inline-block;
                                vertical-align: middle;
                                width: 11px;
                                height: 0;
                                padding-bottom: 11px;
                                border-radius: 50%;
                                margin-right: 19px;
                                background: #d7dde1;
                                @include anim;
                                cursor: pointer;
                                position: relative;
                                button{
                                    @include fill;
                                    opacity: 0;
                                    visibility: hidden;
                                }
                                &:last-child{
                                    margin-right: 0;
                                }
                                &.on{
                                    width: 13px;
                                    height: 0;
                                    padding-bottom: 13px;
                                    background: $accent_color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

footer.footer{
    background: $theme_color1;
    font-size: 15px;
    color: $white;
    padding: 35px 0;
    div.footer_left{
        div{
            display: inline-block;
            vertical-align: top;
            &.logo_box{
                width: 196px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    text-align: center;
                }
                img{
                    max-width: 100%;
                    height: auto;
                }
            }
            &.logotypes{
                padding-top: 20px;
                margin-bottom: 35px;
            }
            &.footer_menu{
                //padding-top: 20px;
                width: 100%;
                color: $white;
                //width: calc(100% - 196px);
                //padding-left: 60px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    text-align: center;
                    padding-left: 0;
                }
                p{
                    margin-bottom: 20px;
                }
                nav{
                    > ul{
                        > li{
                            display: inline-block;
                            vertical-align: top;
                            margin-right: 33px;
                            @media only screen and (max-width: 991px) {
                                margin-right: 0;
                                margin-bottom: 15px;
                                width: 100%;
                            }
                            &:last-child{
                                margin-right: 0;
                                margin-bottom: 0;
                            }

                        }
                    }
                }

            }
        }
    }
    div.devs{
        padding-top: 20px;
        img{
            margin-left: 18px;
        }
        .devs_holder{
            p,a{
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
    a, p.to_up{
        text-decoration: none;
        color: #0e3c4f;
        font-size: 12.5px;
        font-weight: 600;
        cursor: pointer;
    }
    .to_up{
        margin-top: 20px;
        display: inline-block;
    }
}

.top{
    height: 390px;
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center bottom;
    //background-size: cover;
    background-image: url('./../img/nakladka_podstrona.png');
    background-size: cover;
    @media only screen and (max-width: 991px) {
        background-image: url('./../img/nakladka.png');
    }
    &.top2{
        background-image: url('./../img/nakladka.png');
    }
    &.top3{
        background-image: url('./../img/nakladka222.png');
    }
    .vertical_align{
        height: 75%;
        @media only screen and (max-width: 991px) {
            height: 100%;
        }
    }
    div.logo_box{
        img{
            max-width: 100%;
            height: auto;
        }
        @media only screen and (max-width: 400px) {
            margin-bottom: 0;
        }
    }
}

section.login_register, section.contact{
    padding-bottom: 50px;
    position: relative;
    @media only screen and (max-width: 991px) {
        div.login{
            margin-bottom: 30px;
        }
    }
    &:after{
        content: '';
        height: 100%;
        width: 50%;
        position: absolute;
        top: 0;
        right: 0;
        background: #f8fafb;
        @media only screen and (max-width: 991px) {
            display: none;
        }
    }
    .container{
        > div.row{
            margin-left: -60px;
            margin-right: -60px;
            > div.col-md-6{
                padding-left: 60px;
                padding-right: 60px;
            }
        }
    }
    form{
        position: relative;
        z-index: 2;
        > div.row{
            margin-left: -8px;
            margin-right: -8px;
            > div.col-md-6, div.col-md-12{
                padding-left: 8px;
                padding-right: 8px;
            }
        }
    }
}
form{
    .remind_pass{
        display: inline-block;
        text-decoration: underline;
        color: $font_color;
        font-size: 12.5px;
    }
    div.holder{
        h3{
            line-height: 34px;
        }
        margin-bottom: 16px;
        label{
            display: block;
            font-size: 15px;
            color: $font_color;
            line-height: 15px;
            font-weight: 400;
            margin-bottom: 10px;
        }
        input{

            &.default_input{
                width: 100%;
                height: 57px;
                border: 1px solid #d5e5e9;
                background: #eff3f5;
                padding: 0 15px;
                border-radius: 6px;
                color: $font_color;
                font-size: 14px;
                font-weight: 600;
            }
            &.default_submit{
                border: none;
                width: 100%;
                height: 60px;
                font-size: 18px;
                line-height: 18px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 2px;
                color: $header_color;
                background: $accent_color;
                border-radius: 50px;
                margin-top: 30px;
            }
        }
        a{
            outline: none;
            &.default_submit{
                text-align: center;
                border: none;
                width: 100%;
                height: 60px;
                font-size: 18px;
                line-height: 60px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 2px;
                color: $header_color;
                background: $accent_color;
                border-radius: 50px;
                margin-top: 30px;
                display: block;
            }
        }
        &.rules{
            padding: 16px 0 0 0;
            margin-bottom: 0;
            > div{
                display: inline-block;
                vertical-align: top;
                &.box{
                    margin-top: 5px;
                    width: 24px;
                    height: 0;
                    padding-bottom: 22px;
                    background: $white;
                    border-radius: 6px;
                    border: 1px solid #d5e5e9;
                    position: relative;
                    cursor: pointer;
                    > div{
                        @include fill;
                        background-repeat: no-repeat;
                        background-position: center;
                        &.yes{
                            &.on{
                                background-image: url('./../img/check_icon.png');
                            }
                        }
                    }
                }
                &.desc{
                    color: $font_color;
                    padding-left: 17px;
                    font-size: 12.5px;
                    line-height: 24px;
                    width: calc(100% - 24px);
                }
            }
        }
    }
    .text_box{
        position: relative;
        p.word_count{
            font-style: italic;
            color: $font_color;
            font-weight: 600;
            font-size: 12.5px;
            line-height: 12px;
            position: absolute;
            bottom: 13px;
            right: 17px;
        }
        .textarea{
            width: 100%;
            border: 1px solid #d5e5e9;
            background: #eff3f5;
            padding: 15px 107px 15px 15px;
            border-radius: 6px;
            color: $font_color;
            font-size: 15px;
            font-weight: 600;
            resize: none;
            outline: none;
            @media only screen and (max-width: 991px) {
                font-size: 12.5px;
            }
            &.textarea100{
                height: 50px;
                background: $white;
                color: $header_color;
                @media only screen and (max-width: 991px) {
                    height: 200px;
                }
            }
            &.textarea200{
                height: 90px;
                @media only screen and (max-width: 991px) {
                    height: 200px;
                }
            }
            &.textarea1000{
                height: 200px;
            }
        }
    }
    div.file_box{
        margin-top: 15px;
        div.default_font{
            padding-top: 10px;
        }
        div.file_input{
            height: 55px;
            width: 100%;
            border-radius: 50px;
            border: 1px dashed #a5b3b9;
            background: #eff3f5;
            text-align: center;
            font-size: 14px;
            line-height: 14px;
            text-decoration: underline;
            position: relative;
            font-weight: 700;
            letter-spacing: 1px;
            p{
                font-weight: inherit;
            }
            span{
                text-decoration: underline;
            }
            #file{
                @include fill;
                opacity: 0;
                cursor: pointer;
            }
        }
    }
    .radio_list, .check_list, .check_list_one{
         ul {
             li{
                 div{
                    display: inline-block;
                    vertical-align: top;
                    &.default_font{
                        width: calc(100% - 17px);
                        padding-left: 11px;
                        font-weight: 600;
                        &.chosen_title{
                            padding-left: 0;
                            padding-bottom: 10px;
                        }
                        p{
                            font-weight: inherit;
                        }
                    }
                }
            }
        }
    }
    .check_list , .check_list_one{
        > ul{
            > li{
                > div{
                    width: 100%;
                    &.text_box{
                        display: none;
                    }
                }
            }
        }
    }
    .check_list2{
        .check_area2{
            label{
                margin-bottom: 0;
            }
            > div{
                display: inline-block;
                vertical-align: middle;
                &.default_font{
                    width: calc(100% - 23px);
                    padding-left: 11px;
                    font-weight: 600;
                    &.chosen_title{
                        padding-left: 0;
                        padding-bottom: 10px;
                    }
                    p{
                        font-weight: inherit;
                    }
                }
            }
        }
        .hidden_info{
            display: none;
            padding: 15px 0;
            > div{
                display: inline-block;
                vertical-align: middle;
                @media only screen and (max-width: 991px) {
                    vertical-align: top;
                }
                &:first-child{
                    .custom_width {
                        width: 60px;
                    }
                    @media only screen and (max-width: 991px) {
                        padding-top: 10px;
                    }
                }
                &:last-child{
                    width: calc(100% - 60px);
                }
            }
        }
    }
    div.box{
        width: 20px;
        height: 0;
        padding-bottom: 20px;
        position: relative;
        cursor: pointer;
        border-radius: 6px;
        overflow: hidden;
        > div{
            border-radius: 6px;
            @include anim;
            @include fill;
            background-repeat: no-repeat;
            background-position: center;
            border: 1px solid #d5e5e9;
            background-color: #eff3f5;
            background-size: 10px !important;
            &.yes{
                &.on{
                    background-color: #009cc9;
                    z-index: 2;
                    background-image: url('./../img/check_icon2.png');
                }
            }
        }
    }
    div.checkbox_image_box{
        width: 14px;
        height: 0;
        padding-bottom: 14px;
        position: relative;
        cursor: pointer;
        > div{
            @include anim;
            @include fill;
            background-repeat: no-repeat;
            background-position: center;
            border: 1px solid #d5e5e9;
            background-color: #eff3f5;
            background-size: 10px !important;
            &.yes{
                &.on{
                    background-color: #009cc9;
                    z-index: 2;
                    background-image: url('./../img/check_icon2.png');
                }
            }
        }
    }
    div.box_max_6{
        width: 14px;
        height: 0;
        padding-bottom: 14px;
        position: relative;
        margin-top: 9px;
        cursor: pointer;
        > div{
            @include anim;
            @include fill;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('./../img/checkbox.png');
            &.yes{
                &.on{
                    z-index: 2;
                    background-image: url('./../img/checkbox_a.png');
                }
            }
        }
    }
    div.radio_box{
        width: 14px;
        height: 0;
        padding-bottom: 14px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('./../img/radio.png');
        cursor: pointer;
        position: relative;
        @include anim;
        margin-top: 9px;
        &.radio_a{
            background-image: url('./../img/radio_a.png');
        }
        input.radio{
            cursor: pointer;
            @include fill;
            opacity: 0;
            margin: 0;
        }
    }
}

.grey_button{
    display: inline-block;
    font-size: 12.5px;
    line-height: 12.5px;
    text-transform: uppercase;
    padding: 18px 45px;
    letter-spacing: 1px;
    color: $header_color;
    background: #dbe2e5;
    border-radius: 50px;
    font-weight: 700;
    @include anim;
    @media only screen and (max-width: 1500px) {
        padding:15px 18px;
        font-size: 10.5px;
        line-height: 10.5px;
    }
    &:hover{
        background: $accent_color;
    }
}

.delete_button{
    display: inline-block;
    font-size: 12.5px;
    line-height: 12.5px;
    text-transform: uppercase;
    padding: 18px 45px;
    letter-spacing: 1px;
    color: $header_color;
    background: #dbe2e5;
    border-radius: 50px;
    font-weight: 700;
    @include anim;
    @media only screen and (max-width: 1500px) {
        padding:15px 18px;
        font-size: 10.5px;
        line-height: 10.5px;
    }
    &:hover{
        background: #BD2826;
        color: #ffffff;
    }
}
section.step_form{
    header.step_form_header{
        margin-top: -60px;
        font-size: 37.5px;
        font-weight: 300;
        color: $font_color;
        padding-bottom: 20px;
        border-bottom: 2px solid #e0e7eb;
        margin-bottom: 30px;
        @media only screen and (max-width: 1500px) {
            font-size: 32.5px;
            margin-top: -45px;
        }
        @media only screen and (max-width: 1200px) {
            font-size: 26.5px;
            margin-top: -30px;
        }
        @media only screen and (max-width: 991px) {
            text-align: center;
            h1{
                margin-bottom: 15px;
            }
        }
        &.header_summary{
            margin-top: 0;
            border-bottom: none;
        }
        h1,p{
            font-size: inherit;
            font-weight: inherit;
        }
        h1{
            padding-top: 10px;
            color: $header_color;
            span{
                color: $accent_color2;
            }
        }
        ul{
            text-align: right;
            @media only screen and (max-width: 991px) {
                text-align: center;
            }
            li{
                display: inline-block;
                vertical-align: top;
                margin-right: 20px;
                @media only screen and (max-width: 1200px) {
                    margin-right: 10px;
                }
                &:last-child{
                    //margin-right: 0;
                }
            }
        }
    }
    form{
        div.step_form_holder{
            margin-bottom: 20px;
            > div{
                display: inline-block;
                vertical-align: top;
                &.number{
                    width: 36px;
                    height: 0;
                    padding-bottom: 36px;
                    position: relative;
                    border-radius: 50%;
                    color: $white;
                    font-size: 16px;
                    line-height: 16px;
                    font-weight: 700;
                    text-align: center;
                    background: $accent_color2;
                    @media only screen and (max-width: 991px) {
                        width: 20px;
                        padding-bottom: 20px;
                        font-size: 10.5px;
                        line-height: 10.5px;
                        margin-top: 8px;
                    }
                }
                &.desc{
                    width: calc(100% - 36px);
                    @media only screen and (max-width: 991px) {
                        width: calc(100% - 20px);
                    }
                    > div{
                        padding-top: 5px;
                        padding-left: 16px;
                        header{
                            margin-bottom: 10px;
                            color: $header_color;
                            h2{
                                font-size: 17px;
                                line-height: 27px;
                            }
                        }
                    }
                    .hidden_area{
                        padding-top: 30px;
                    }
                }
            }
        }
    }
    div.form_summary{
        padding-top: 30px;
        background: #eef3f5;
        div.step_progress{
            margin-bottom: 38px;
            > div.step_name{
                margin-bottom: 23px;
                > div{
                    display: inline-block;
                    vertical-align: top;
                    &.number{
                        width: 56px;
                        height: 0;
                        padding-bottom: 56px;
                        position: relative;
                        border-radius: 50%;
                        color: #ffffff;
                        font-size: 25px;
                        line-height: 25px;
                        font-weight: 700;
                        text-align: center;
                        background: #009cc9;
                        @media only screen and (max-width: 991px) {
                            width: 36px;
                            padding-bottom: 36px;
                            font-size: 16px;
                            line-height: 16px;
                            margin-top: 23px;
                        }
                    }
                    &.desc{
                        width: calc(100% - 56px);
                        @media only screen and (max-width: 991px) {
                            width: calc(100% - 36px);
                        }
                        > div{
                            padding-top: 15px;
                            padding-left: 20px;
                            color: #003a55;
                            h2{
                                font-size: 17px;
                                line-height: 27px;
                            }
                        }
                    }
                }
            }
            > .progress1{
                div.progress_bar{
                    margin-top: 5px;
                    width: 100%;
                    background: $white;
                    position: relative;
                    height: 5px;
                    border-bottom: 2px solid #e7ebed;
                    border-radius: 5px;
                    > div{
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        background: $accent_color;
                    }
                }
                .progress_count{
                    white-space: nowrap;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 12px;
                    color: $font_color;
                    @media only screen and (max-width: 991px) {
                        padding-top: 15px;
                        text-align: center;
                    }
                    strong{
                        color: $header_color;
                    }
                }
            }
        }
        .step_form_header{
            @media only screen and (max-width: 991px) {
                margin-bottom: 0;
            }
        }
    }
}

section.after_login_view{
    header.after_login_header{
        padding:5px 0 25px 0;
        border-bottom: 2px solid #e0e7eb;
        margin-bottom: 50px;
        @media only screen and (max-width: 991px) {
            text-align: center;
          }
        h2{
            font-weight: 700;
            color: $header_color;
            span{
                font-weight: 300;
                color: $font_color;
            }
            strong{
                color:$accent_color;
            }
        }
    }
    .after_login_view_content{
        > div.row{
            margin-left: -35px;
            margin-right: -35px;
            @media only screen and (max-width: 1200px) {
                margin-left: -15px;
                margin-right: -15px;
            }
           > div.col-md-6{
                padding-left: 35px;
                padding-right: 35px;
                @media only screen and (max-width: 1200px) {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }
        div{
            &.forms_list{
                padding-top: 25px;
                > ul{
                    > li{
                        margin-bottom: 30px;
                    }
                }
              div.form_box{
                  border: 2px solid #e0e7eb;
                  border-radius: 10px;
                  @include anim;
                  &:hover{
                      transform: scale(1.05);
                      -webkit-box-shadow: 0px 0px 28px 0px rgba(224,231,235,1);
                    -moz-box-shadow: 0px 0px 28px 0px rgba(224,231,235,1);
                    box-shadow: 0px 0px 28px 0px rgba(224,231,235,1);
                  }
                  div{
                      &.f_top{
                          border-bottom:2px solid #e0e7eb;
                          padding: 20px 0;
                          @media only screen and (max-width: 991px) {
                            text-align: center;
                          }
                          > ul{
                              padding:0 30px;
                              @media only screen and (max-width: 1200px) {
                                padding: 0 15px;
                              }
                              > li{
                                  display: inline-block;
                                  vertical-align: top;
                                  @media only screen and (max-width: 991px) {
                                    width: 100% !important;
                                  }
                                  &.top_left{
                                      width: 54%;
                                  }
                                  &.top_right{
                                      width: 46%;
                                      text-align: right;
                                      @media only screen and (max-width: 991px) {
                                        text-align: center;
                                        padding-top: 15px;
                                      }
                                  }
                                  > div{
                                      display: inline-block;
                                      vertical-align: top;
                                      @media only screen and (max-width: 991px) {
                                        display: block;
                                      }
                                      &.number{
                                          position: relative;
                                          width: 55px;
                                          height: 0;
                                          padding-bottom: 55px;
                                          text-align: center;
                                          color: $white;
                                          font-size: 25px;
                                          line-height: 25px;
                                          background: $theme_color1;
                                          border-radius: 0;
                                          border-radius: 50%;
                                          font-weight: 700;
                                          @media only screen and (max-width: 991px) {
                                            margin: 0 auto;
                                          }

                                          p{
                                              font-weight: inherit;
                                          }
                                      }
                                      &.desc{
                                          width: calc(100% - 55px);
                                          padding-left: 20px;
                                          padding-top: 10px;
                                          color: $font_color;
                                          font-size: 12.5px;
                                          @media only screen and (max-width: 991px) {
                                            padding-left: 0;
                                            width: 100%;
                                          }
                                          h4{
                                              color: $header_color;
                                              font-size: 16.5px;
                                              line-height: 20px;
                                          }
                                      }
                                  }
                              }
                          }
                      }
                      &.f_bottom{
                          padding: 18px 0;
                          background: #eef3f5;
                          @media only screen and (max-width: 991px) {
                            text-align: center;
                          }
                          ul{
                              padding: 0 30px;
                              @media only screen and (max-width: 1200px) {
                                padding: 0 15px;
                              }
                              > li{
                                  display: inline-block;
                                  vertical-align: top;
                                  @media only screen and (max-width: 991px) {
                                    width: 100% !important;
                                  }
                                  font-size: 12.5px;
                                  line-height: 12.5px;
                                  @media only screen and (max-width: 1200px) {
                                    font-size: 9px;
                                    line-height: 9px;
                                  }
                                  color:$font_color;
                                  &.bottom_left{
                                    width: 30%;
                                  }
                                  &.bottom_right{
                                    width: 70%;
                                  }
                                  a{
                                      color: $header_color;
                                      text-decoration: underline;
                                      display: inline-block;
                                      margin-right: 20px;
                                      @media only screen and (max-width: 1500px) {
                                        font-size: 10px;
                                        margin-right: 10px;
                                      }
                                      @media only screen and (max-width: 1200px) {
                                        font-size: 9px;
                                        margin-right: 5px;
                                      }
                                      &:last-child{
                                          nav-right: 0
                                      }
                                  }
                                  &.bottom_left, &.bottom_right{
                                      div.status{
                                          span{
                                            font-weight: 700;
                                          }
                                          &.send{
                                              span{
                                                  color: $theme_color1;
                                              }
                                          }
                                          &.accept{
                                            span{
                                                color: #64a309;
                                            }
                                          }
                                          &.noaccept{
                                            span{
                                                color: #c61d1d;
                                            }
                                          }
                                          &.improvement{
                                            span{
                                                color: #ed8413;
                                            }
                                          }
                                          &.inprogress{
                                              span{
                                                  color: $header_color;
                                              }
                                          }
                                      }
                                  }
                                  &.bottom_right{
                                      text-align: right;
                                      @media only screen and (max-width: 991px) {
                                        text-align: center;
                                      }
                                      div.progress_bar_small{
                                          width: 100%;
                                          > div{
                                              display: inline-block;
                                              vertical-align: middle;
                                              &.progress_count{
                                                  width: 37%;
                                                  @media only screen and (max-width: 991px) {
                                                    width: 100%;
                                                  }
                                              }
                                              &.bar{
                                                  width: calc(100% - 37%);
                                                  position: relative;
                                                  height: 5px;
                                                  background: $white;
                                                  border-radius: 5px;
                                                  border-bottom: 2px solid #e7ebed;
                                                  overflow: hidden;
                                                  @media only screen and (max-width: 991px) {
                                                    width: 100%;
                                                    margin: 15px 0;
                                                  }
                                                  > div{
                                                      position: absolute;
                                                      top: 0;
                                                      left: 0;
                                                      width: 30%;
                                                      height: 100%;
                                                      background: $accent_color;
                                                  }
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
            }
        }
        header{
            padding-top: 5px;
            h3{
               font-size: 25px;
               line-height: 36px;
               font-weight: 300;
               color: $header_color;
               @media only screen and (max-width: 1200px) {
                text-align: center;
                margin-bottom: 10px;
               }
               strong{
                   font-weight: 700;
               }
            }
        }
        div.your_data{
            background: #eef3f5;
            padding: 25px 40px;
            border-radius: 10px;
            @media only screen and (max-width: 1200px) {
                padding: 15px;
              }
              @media only screen and (max-width: 991px) {
                margin-bottom: 30px;
              }
            form{
                padding-top: 25px;
            }
            div.edit_holder{
                padding: 16px 19px;
                background: $white;
                border-radius: 10px;
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }
                label{
                    display: block;
                    color: $font_color;
                    font-size: 12px;
                }
                .edit_input{
                    border: none;
                    font-size: 14px;
                    height: 30px;
                    width: 100%;
                    outline: none;
                }
            }
        }
    }
    &.view2{
        header.after_login_header{
            div.default_font{
                h2{
                    font-size: 37px;
                    line-height: 50px;
                }
            }
            .text-right{
                .grey_button{
                    position: relative;
                    top: 10px;
                }
            }
        }
        .overall_progress{
            border-radius: 10px;
            margin-top: 25px;
            padding: 18px 30px;
            background: $header_color;
            > div{
                display: inline-block;
                vertical-align: middle;
                &.steps{
                    width: 32%;
                    &.default_font{
                        color: $white;
                    }
                }
                &.progress_bar_small{
                    width: calc(100% - 32%);
                    > div{
                        display: inline-block;
                        vertical-align: middle;
                        &.bar{
                            width: 58%;
                            position: relative;
                            height: 5px;
                            background: $white;
                            border-radius: 5px;
                            overflow: hidden;
                            > div{
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 30%;
                                height: 100%;
                                background: $accent_color;
                            }
                        }
                        &.progress_count{
                            text-align: right;
                            width: 42%;
                            text-align: right;
                            font-size: 12.5px;
                            line-height: 12.5px;
                            color: #717a8c;
                            span{
                                font-weight: 700;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
        div{
            &.forms_list{
                div.form_box{
                    div{
                        &.f_bottom{
                            ul{
                             >  li{
                                   &.bottom_right{
                                       width: 100%;
                                       div.progress_bar_small{
                                            width: 100%;
                                            > div{
                                                &.progress_count{
                                                    width: 25%;
                                                }
                                                &.bar{
                                                    width: calc(100% - 25%);
                                                }
                                            }
                                        }
                                   }
                               }
                           }
                       }
                    }
                }
            }
        }
    }
}
.text-right{
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
}

.half_white{
    position: relative;
    &:after{
        content: '';
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background: #f8fafb;
    }
}

.previewsContainer{
    text-align: right;
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    li{
        width: 100%;
        font-size: 17px;
        padding: 12px 0;
        border: 1px dashed #a5b3b9;
        background: #eff3f5;
        border-radius: 50px;
        color: #003a55;
        font-weight: 700;
        &.row{
            margin: 0;
            margin-top: 10px !important;
        }
        .glyphicon{
            font-size: 17px;
            color: #003a55;
        }
        > div{
            display: inline-block;
            vertical-align: top;
            &.lp{
                width: 30px;
            }
            &.name{
                width: calc(100% - (30px + 80px));
            }
            .file{
                display: none;
            }
            @media only screen and (max-width: 991px) {
                margin-right: 10px;
                &:last-child{
                    margin-right: 0;
                }
            }
            &.action{
                width: 80px;
                div{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 10px;
                    &.delete{
                        cursor: pointer;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.dz-message{
    padding: 17px 0;
    border-radius: 50px;
    border: 1px dashed #a5b3b9;
    background: #eff3f5;
    font-size: 12px;
    font-weight: 900;
    text-align: center;
    color: #003a55;
}

.file_box{
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
}

.--tooltip{
    background: $white;
    height: 150px;
    width: 400px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid $accent_color;
    > div{
        position: absolute;
        height: 100%;
        &.img{
            width: 37%;
            top: 0;
            left: 0;
            > div{
                @include fill;
                .--photo{
                    @include fill;
                }
            }
        }
        &.desc{
            width: 63%;
            top: 0;
            left: 37%;
            padding: 15px 10px;
            color: $font_color;
            h2{
                color: $accent_color;
                font-size: 17px;
                line-height: 20px;
                margin-bottom: 5px;
            }
            font-size: 13px;
            line-height: 17px;
        }
    }
}

section.faq{
    div.faq_list{
        width: 100%;
        > ul{
           > li{
               margin-bottom: 50px;
               @media only screen and (max-width: 1500px) {
                margin-bottom: 30px;
               }
               cursor: pointer;
                > .question{
                    padding-bottom: 30px;
                    border-bottom: 1px solid $font_color;
                    @media only screen and (max-width: 1500px) {
                        padding-bottom: 15px;
                    }
                    button{
                        width: 100%;
                        border: none;
                        background: transparent;
                        text-align: left;
                        > span{
                            display: inline-block;
                            vertical-align: middle;
                            &.number{
                                position: relative;
                                width: 50px;
                                height: 0;
                                padding-bottom: 50px;
                                border-radius: 50%;
                                background:$accent_color;
                                color: $header_color;
                                text-align: center;
                                font-size: 20px;
                                font-weight: 700;
                                line-height: 50px;
                            }
                            &.desc{
                                @media only screen and (max-width: 1500px) {
                                    padding-top: 5px;
                                }
                                width: calc(100% - 50px);
                                padding-left: 20px;
                                //> div.default_font{
                                //    h3{
                                //        color: $header_color;
                                //        font-weight: 700;
                                //    }
                                //}
                            }
                        }
                    }
                }
                div.answer{
                    display: none;
                    padding: 30px;
                    background: #f1f5f7;
                }
            }
        }
    }
}

section.contact{
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    div.desc{
        color: #717a8c;
        font-size: 25px;
        line-height: 38px;
        @media only screen and (max-width: 1500px) {
            font-size: 18px;
            line-height: 29px;
        }
        img,p{
            margin-bottom: 30px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        a{
            color: $accent_color;
            text-decoration: underline;
        }
    }
    div.map{
        padding-bottom: 65%;
        height: 0;
        width: 100%;
        position: relative;
        border: 2px solid $white;
        > iframe{
            @include fill;
        }
    }
    .contact_data{
        border-bottom: 1px solid #e0e7eb;
        padding-bottom: 50px;
        margin-bottom: 50px;
    }
   .support{
    div.default_font{
        color: #717a8c;
    }
    }
}

iframe{
    border: none;
}

.news{
    padding-bottom: 30px;
    .section_header{
       div.default_font{
        margin-bottom: 30px;
       }
    }
    div.news_list{
        > ul{
            text-align: center;
            > li{
                text-align: left;
                width: 49%;
                display: inline-block;
                vertical-align: middle;
                margin-right: 2%;
                margin-bottom: 30px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                &:nth-child(2n), &:last-child{
                    margin-right: 0;
                }
                article{
                    background: #f1f5f7;
                    border-radius: 10px;
                    padding: 15px 25px;
                    border: 1px solid #d7dde1;
                    header{
                        margin-bottom: 15px;
                        div.date{
                            margin-bottom: 5px;
                            color: $accent_color;
                            font-size: 15px;
                            line-height: 30px;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}

.brand_map{

    .map_holder{
        position: relative;
        width: 100%;
        height: 100vh;
        @media only screen and (max-width: 991px) {
            height: 250px;
        }
        iframe{
            @include fill;
        }
    }
}

.brand_list{
    padding-top: 60px;
    background: #f8fafb;
    .brand_search{
        -webkit-box-shadow: 0px 0px 28px 0px rgba(224,231,235,1);
        -moz-box-shadow: 0px 0px 28px 0px rgba(224,231,235,1);
        box-shadow: 0px 0px 28px 0px rgba(224,231,235,1);
        margin-bottom: 60px;
        form{
            width: 100%;
            display: table;
           background: $white;
            border: 1px solid #c8d0de;
            border-radius: 5px;
            @media only screen and (max-width: 991px) {
                display: block;
            }
           > div{
                display: table-cell;
                vertical-align: middle;
                height: 100%;
                position: relative;
                border-right:1px solid #e3e7ee;
                @media only screen and (max-width: 991px) {
                    display: block;
                    border-right: none;
                    border-bottom:1px solid #e3e7ee;
                    width: 100% !important;
                    height: 79px;
                }
                &:nth-child(1){
                    font-size:16px;
                    line-height: 16px;
                    color: $header_color;
                    width: 149px;
                    text-align: center;
                    @media only screen and (max-width: 991px) {
                        line-height: 79px;
                    }
                }
                &:nth-child(2){
                    width: 287px;
                }
                /*&:nth-child(3){
                    width: calc(100% - (149px + 297px + 217px));
                }*/
                &:nth-child(3){
                    width: 217px;
                    border-right:none;
                    .b_submit{
                        @include fill;
                        background: $accent_color;
                        color: $wheme_color2;
                        text-align: center;
                        color: $header_color;
                        font-size:16px;
                        font-weight: 700;
                        border:none;
                    }
                }
                .chosen-container{
                    height: 79px;
                    font-size: 14.5px;
                    color: $header_color;
                    font-weight: 600;
                    .chosen-single{
                        border-radius: 0;
                        border: none;
                        border-bottom: 1px solid #c8d0de;
                        span{
                            margin-right: 60px;
                            color: $header_color;
                        }
                        div{
                            right: 0;
                            width: 60px;
                            b{
                                background-repeat: no-repeat;
                                background-position: center;
                                background-image: url('./../img/select_arr.png');
                            }
                        }
                    }
                    .chosen-drop{
                        margin-top: 0;
                        border: 1px solid #c8d0de;
                        border-top: 1px solid $white;
                    }
                    .chosen-results {
                        li.highlighted{
                            background: $accent_color;
                        }
                    }
                }
            }
        }
    }
    .list_result{
        margin-bottom: 50px;
        ul{
            li{
                display: inline-block;
                vertical-align: top;
                width: 49%;
                margin-right: 2%;
                margin-bottom: 2%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 15px;
                }
                @include anim;
                &:hover{
                    transform: scale(1.03);
                    -webkit-box-shadow: 0px 0px 28px 0px rgba(224,231,235,1);
                    -moz-box-shadow: 0px 0px 28px 0px rgba(224,231,235,1);
                    box-shadow: 0px 0px 28px 0px rgba(224,231,235,1);
                }
                &:nth-child(2n), &:last-child{
                    margin-right: 0;
                }
                a{
                    display: block;
                }
                article{
                    border: 1px solid #e9ecf1;
                    background: $white;
                    padding: 9px;
                    div{
                        display: inline-block;
                        vertical-align: top;
                        &.image{
                            width: 128px;
                            height: 0;
                            padding-bottom: 128px;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            border: 1px solid #e9ecf1;
                            border-bottom: 2px solid #e9ecf1;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                padding-bottom: 100%;
                            }
                        }
                        &.desc{
                            width: calc(100% - 128px);
                            font-size: 12.5px;
                            line-height: 20px;
                            color:$header_color;
                            padding: 10px 30px 0 30px;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                text-align: center;
                            }
                            header{
                                h3{
                                    font-size: 18px;
                                    line-height: 28px;
                                }
                            }
                            .short_desc{
                                margin: 10px 0 15px 0;
                                display: block;
                            }
                            .more_btn{
                                font-size: 11px;
                                line-height: 11px;
                                padding: 10px 33px;
                                letter-spacing: 1px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.brand_details{
    padding-top: 150px;
    width: 100%;
    background: no-repeat;
    background-position: center top;
    background-size: cover;
    background-image: url('./../img/brand_cover.jpg');
    position: relative;
    .brand_details_top{
        margin-bottom: 50px;
    }
    .brand_bg{
        @include fill;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.4;
        background-image: url('./../img/brand_cover.jpg');

    }
    div.cover{
        background: rgba(255,255,255,1);
        background: -moz-linear-gradient(top, rgba(255,255,255,1) 19%, rgba(255,255,255,0.53) 57%, rgba(0,0,0,0) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(19%, rgba(255,255,255,1)), color-stop(57%, rgba(255,255,255,0.53)), color-stop(100%, rgba(0,0,0,0)));
        background: -webkit-linear-gradient(top, rgba(255,255,255,1) 19%, rgba(255,255,255,0.53) 57%, rgba(0,0,0,0) 100%);
        background: -o-linear-gradient(top, rgba(255,255,255,1) 19%, rgba(255,255,255,0.53) 57%, rgba(0,0,0,0) 100%);
        background: -ms-linear-gradient(top, rgba(255,255,255,1) 19%, rgba(255,255,255,0.53) 57%, rgba(0,0,0,0) 100%);
        background: linear-gradient(to bottom, rgba(255,255,255,1) 19%, rgba(255,255,255,0.53) 57%, rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100px;
    }
    div.brand_logo{
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        background-color:$white;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 70% auto;
        @media only screen and (max-width: 991px) {
            width: 130px;
            height: 0;
            padding-bottom: 130px;
            margin: 0 auto;
        }
    }
    .brand_name{
        padding-top: 40px;
        padding-left: 60px;
        @media only screen and (max-width: 1500px) {
            padding-left: 35px;
        }
        @media only screen and (max-width: 1200px) {
            padding-left: 20px;
        }
        @media only screen and (max-width: 991px) {
            padding-left: 0;
            padding-top: 50px;
            text-align: center;;
        }
        .default_font{
            color: $white;
            margin-bottom: 15px;
        }
        .social{
            padding:10px 24px;
            border: 1px solid rgba(255,255,255,0.2);
            display: inline-block;
            border-radius: 50px;
           ul{
               li{
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 24px;
                    &:last-child{
                        margin-right: 0;
                    }
               }
           }
        }
    }
    .brand_details_bottom{
        display: table;
        width: 100%;
        margin-bottom: 50px;
        @media only screen and (max-width: 991px) {
            display: block;;
        }
        > div{
            display: table-cell;
            vertical-align: middle;
            width: 50%;
            @media only screen and (max-width: 991px) {
                display: block;
                width: 100%;
            }
            &.map_small{
                //height: 0;
                //padding-bottom: 26%;
                height: 366px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }
            &.desc{
                div.default_font{
                    padding-left: 50px;
                    color: $white;
                    @media only screen and (max-width: 991px) {
                        padding-left: 0;
                        padding-top: 50px;
                    }
                }
            }
        }

    }
}

.about_brand{
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin-bottom: 30px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 15px;
        }

    }
    .default_font{
        h1,h2,h3,h4,h5,h6,p,ul,ol,a{
            &:last-child{
                margin-bottom: 30px;
                @media only screen and (max-width: 1500px) {
                    margin-bottom: 15px;
                }
            }
        }
        ul{
            li{
                color: $accent_color;
                p{
                    margin-bottom: 0 !important;
                    color: $font_color;
                }
            }
        }
    }
    .splt_desc{
        margin: 0 -50px;
        > div{
            padding: 0 50px;
        }
    }
    div{
        &.desc_top{
            padding: 60px 0;
            font-size: 16px;
            line-height: 30px;
            @media only screen and (max-width: 1500px) {
                font-size: 14px;
                line-height: 28px;
            }
            @media only screen and (max-width: 1200px) {
                padding: 30px 0;
            }
            p{
                //font-weight: 600;
            }
        }
        &.split{
           > div{
               display: table;
               width: 100%;
               min-height: 500px;
               @media only screen and (max-width: 1500px) {
                min-height: 400px;
               }
               @media only screen and (max-width: 991px) {
                min-height: 0;
                display: block;
               }
               > div{
                   display: table-cell;
                   vertical-align: middle;
                   width: 50%;
                   height: 100%;
                   @media only screen and (max-width: 991px) {
                    min-height: 0;
                    display: block;
                    width: 100%;
                   }
               }
               @media only screen and (max-width: 991px) {
                width: 100%;
                display: flex;
                flex-flow: column;
                > div{
                    align-items: stretch;
                    &.image{
                        order: 2;
                    }
                    &.desc{
                        order: 1;
                    }
                }
                }
               &.split_left{
                div.desc{
                    padding-left: 50px;
                    @media only screen and (max-width: 991px) {
                        padding-left: 0;
                    }
                }
               }
               &.split_right{
                   div.desc{
                       padding-right: 50px;
                       @media only screen and (max-width: 991px) {
                            padding-right: 0;
                        }
                   }
                }
                .desc{
                    @media only screen and (max-width: 991px) {
                        padding: 50px 0;
                    }
                }
                .image{
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: cover;
                  @media only screen and (max-width: 991px) {
                    height: 300px;
                    margin-bottom: 15px;
                  }
                }
            }
        }
    }
}

.brand_gallery{
    .movie_box{
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 47%;
        margin-bottom: 50px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 15px;
        }
        iframe{
            @include fill;
        }
    }
    .brand_gal_list{
        margin-bottom: 30px;
        ul{
            margin: 0 -15px;
            li{
                display: inline-block;
                vertical-align: top;
                width: 25%;
                padding: 0 15px;
                margin-bottom: 30px;
                @media only screen and (max-width: 991px) {
                    width: 100% !important;
                    margin-bottom: 15px;
                }
                a{
                    display: block;
                    width: 100%;
                    overflow: hidden;
                }
                &:hover{
                    .image{
                        transform: scale(1.1);
                    }
                }
                &:nth-child(1){
                    width: 33.33%;
                }
                &:nth-child(2){
                    width: 33.33%;
                }
                &:nth-child(3){
                    width: 33.33%;
                }
                .image{
                    @include anim;
                    width: 100%;
                    height: 0;
                    padding-bottom: 73%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    @media only screen and (max-width: 991px) {
                        padding-bottom: 47%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .side_left, .side_right{
        padding: 15px !important;
    }
}

.map_holder{
    .gm-style {
        .gm-style-iw-d {
            overflow: visible !important;
            padding: 15px;
        }
        .gm-style-iw-c {
            background: rgba(24,142,173,0.9);
            color: $white;
            padding: 0;
            min-width: 360px;
            overflow: visible;
            &:after{
                top: 100%;
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                content: '';
                width: 28px;
                height: 14px;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url('./../img/triangle.png');
                -moz-transform: scale(1, -1);
                -webkit-transform: scale(1, -1);
                -o-transform: scale(1, -1);
                -ms-transform: scale(1, -1);
                transform: scale(1, -1);
            }
        }
        .gm-style-iw-t{
            &:after{
                display: none !important;
            }
        }
    }
    &.map_holder2{
        .gm-style {
            .gm-style-iw-d {
                overflow: visible !important;
                padding: 15px;
            }
            .gm-style-iw-c {
                background: #f8fafb;
                color: $white;
                padding: 0;
                min-width: 360px;
                overflow: visible;
                border-radius: 0 !important;
                &:after{
                    top: 100%;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    content: '';
                    width: 28px;
                    height: 14px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-image: url('./../img/triangle.png');
                    -moz-transform: scale(1, -1);
                    -webkit-transform: scale(1, -1);
                    -o-transform: scale(1, -1);
                    -ms-transform: scale(1, -1);
                    transform: scale(1, -1);
                }
            }
            .gm-style-iw-t{
                &:after{
                    display: none !important;
                }
            }
        }
        .map_info_box{
            > div{
                &.image{
                    border: 1px solid #e9ecf1;
                    border-bottom: 2px solid #e9ecf1;
                }
                &.desc{
                    padding-left: 30px;
                    color: #003a55;
                    text-align: left;
                }
            }
        }
    }
}

.map_info_box{
    > div{
        display: inline-block;
        vertical-align: middle;
        &.image{
            width:115px;
            height: 115px;
            line-height: 115px;
            font-size: 15px;
            font-weight: 700;
            color: $header_color;
            text-align: center;
            position: relative;
            p{
                position: relative;
                z-index: 2;
            }
            div{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100% auto;
            }
        }
        &.desc{
            width:  calc(100% - 115px);
            text-align: center;
            font-size: 18px;
            p{
                font-weight: 700;
            }
            a{
                margin-top: 20px;
                width: 105px;
                height: 32px;
                color: $header_color;
                font-size:11px;
                line-height: 32px;
                text-transform: uppercase;
                text-align: center;
                background: $accent_color;
                border-radius: 50px;
                display: inline-block;
                font-weight: 700;
            }
        }
    }
}

.doc_list{
    padding-bottom: 50px;
    ul{
        margin: 0 -15px;
        li{
            padding: 0 15px;
            width: 50%;
            display: inline-block;
            vertical-align: top;
            margin-bottom: 30px;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            a{
                display: block;
                width: 100%;
                height: 50px;
                line-height: 50px;
                background-repeat: no-repeat;
                background-position: left 15px center;
                background-image: url('./../img/doc_icon.png');
                padding: 0 30px 0 55px;
                font-size: 15px;
                color: $font_color;
                font-weight: 600;
                border: 1px solid #e9ecf1;
                //display: -webkit-box;
                //-webkit-line-clamp: 1;
                //-webkit-box-orient: vertical;
                //overflow: hidden;
                //text-overflow: ellipsis;
                @include anim;
                &:hover{
                    color:#2f93b9;
                    background-color: $accent_color;
                }
            }
        }
    }
}
.text{
    padding-bottom: 50px;
    .holder{
        input{
            width: 100%;
            height: 57px;
            border: 1px solid #d5e5e9;
            background: #eff3f5;
            padding: 0 15px;
            border-radius: 6px;
            color: #343942;
            font-size: 14px;
            font-weight: 600;
            outline: none;
        }
        textarea{
            width: 100%;
            min-height: 150px;
            border: 1px solid #d5e5e9;
            background: #eff3f5;
            padding: 0 15px;
            border-radius: 6px;
            color: #343942;
            font-size: 18px;
            font-weight: 600;
            resize: none;
            outline: none;
        }
        .chosen-container{
            height: 79px;
            font-size: 14.5px;
            color: $header_color;
            font-weight: 600;
            height: auto;
            .chosen-single{
                border-radius: 6px;
                border: none;
                border-bottom: 1px solid #c8d0de;
                height: 57px;
                border: 1px solid #d5e5e9;
                padding: 12px 0 0 30px;
                background: #eff3f5;
                span{
                    margin-right: 60px;
                    color: $header_color;
                }
                div{
                    right: 0;
                    width: 60px;
                    b{
                        background-repeat: no-repeat;
                        background-position: center;
                        background-image: url('./../img/select_arr.png');
                    }
                }
            }
            .chosen-drop{
                margin-top: 0;
                border: 1px solid #c8d0de;
                border-top: 1px solid $white;
            }
            .chosen-results {
                li.highlighted{
                    background: $accent_color;
                }
            }
        }
    }
}

.field_doradcy_form_submit, .field_doradcy_form_submittmp  {
    border: none;
    width: 100%;
    height: 60px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #003a55;
    background: #afdb29;
    border-radius: 50px;
    margin-top: 30px;
}

.multi_list{
    padding-top: 20px;
    li{
        display: inline-block;
        vertical-align: top;
        height: 30px;
        padding: 0 15px;
        line-height: 30px;
        border-radius: 50px;
        background: $accent_color;
        margin-right: 10px;
        &:last-child{
            margin-right: 0;
        }
    }
}

.previewsContainer li{
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
}

.add_place123{
    h3{
        margin-bottom: 5px;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
    }
}


.short_news{
    padding: 90px 0;
    &.other_news{
        padding: 70px 0;
        background: #eff2f3;
        @media only screen and (max-width: 991px) {
            padding: 50px 0 0 0;
        }
        div.news_list {
            ul {
                li{
                    width: 33.33%;
                    min-height: 470px;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                    &.news_with_image{
                        div{
                            &.image{
                                height: 320px;
                            }
                            &.desc{
                                background: $white;
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 1500px) {
        padding: 50px 0;
    }
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
    div.news_list{
        ul{
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            margin: 0 -20px;
            @media only screen and (max-width: 991px) {
                display: block;
            }
            li{
               width: 25%;
               min-height: 395px;

                padding: 0 20px;

                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin: 0 0 30px 0;
                    min-height: 0;
                    height: auto;
                }
                article{
                    -webkit-box-shadow: 0px 0px 28px 0px rgba(224,231,235,1);
                    -moz-box-shadow: 0px 0px 28px 0px rgba(224,231,235,1);
                    box-shadow: 0px 0px 28px 0px rgba(224,231,235,1);
                    border: 1px solid #e9edf2;
                    border-radius: 10px;
                    overflow: hidden;
                }
               &.news_with_image{
                   div{
                        &.image{
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: cover;
                            height: 220px;
                            position: relative;
                            &:after{
                                content: '';
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 100%;
                                height: 10px;
                                background: rgba(175,219,41,0.8);
                            }
                        }
                        &.desc{
                            height: calc(100% - 220px);
                        }
                   }
               }
               &.news_without_image{
                   div{
                    &.desc{
                        height: 100%;
                        .short_desc{
                            font-size: 12.5px;
                            line-height: 25px;
                            color: #9da3ae;
                        }
                    }
                   }
               }
               article{
                   height: 100%;
               }
               div{
                   &.desc{
                       padding: 27px;
                       .news_header{
                           margin-bottom: 15px;
                           .date{
                               font-size: 12.5px;
                               color: #9da3ae;
                               margin-bottom: 5px;
                           }
                           h2,h3{
                               font-size: 16px;
                               color: $header_color;
                               line-height: 26px;
                           }
                       }
                   }
               }
            }
        }
    }
    .more_news{
        padding-top: 60px;
        text-align: center;
        @media only screen and (max-width: 991px) {
            padding-top: 20px;
        }
    }
    &.main_news{
        position: relative;
        z-index: 2;
        background: $white;
        padding-bottom: 60px;
        .news_list{
            ul{
                flex-wrap: wrap;
                li{
                    margin-bottom: 40px;
                }
            }
        }
    }
}

.maps{
    //background: url(./../img/home_maps_bg.png) center bottom 47% no-repeat;
    background-size: cover;
    @media only screen and (max-width: 991px) {
        text-align: center;
        .row{
            display: flex;
            flex-flow: column;
            > div{
                align-items: stretch;
                &:first-child{
                    order: 2;
                    margin-top: 30px;
                }
                &::last-child{
                    order: 1;
                }
            }
        }
    }
    .right_side{
        padding-left: 120px;
        color: #717a8c;
        @media only screen and (max-width: 991px) {
            padding-left: 0;
        }
        div.desc{
            margin-bottom: 50px;
            @media only screen and (max-width: 991px) {
                text-align: center;
                margin-bottom: 30px;
            }
            .desc_top{
                font-size: 22px;
                line-height: 35px;
                margin-bottom: 30px;
                p{
                    font-weight: 300;
                }
            }
        }
    }
    .image{
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 0;
        padding-bottom: 97%;
        background-size: cover;
        position: relative;

    }
}

.paggination_boxx{
    text-align: center;
    margin-top: 20px;
    > ul{
        display: inline-block;
        background: $white;
        border-bottom: 2px solid #dfdfdf;
        border-radius: 10px;
        overflow: hidden;
        > li{
            a{
                display: block;
                color: #7e8086;
                &:hover{
                    text-decoration: none;
                }
            }
            display: inline-block;
            vertical-align: middle;
            height: 100%;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            &.arr_holder{
                text-align: center;
                height: 44px;
                line-height: 44px;
                background: #f4f6f8;
                &.prev{
                    img{
                        margin-right: 10px;
                    }
                }
                &.next{
                    img{
                        margin-left: 10px;
                    }
                }
                a{
                    padding: 0 30px;
                    font-weight: 700;
                }
            }
            &.sites_holder{
                background: $white;
                font-size: 14px;
                @media only screen and (max-width: 991px) {
                    padding: 10px 0 5px 0;
                }
                ul{
                    padding: 0 10px;
                    li{
                        display: inline-block;
                        vertical-align: top;
                        min-width: 30px;
                        line-height: 30px;
                        text-align: center;
                        height: 30px;
                        border-radius: 50%;
                        margin: 0 5px;

                        &.active{
                            background: $accent_color;
                           a{
                            color: $white;
                           }
                        }
                    }
                }
            }
            &.go_to{
                padding: 0 60px 0 30px;
                font-size: 11.5px;
                @media only screen and (max-width: 991px) {
                    padding: 5px 0 10px 0;
                }
                div{
                    display: inline-block;
                    vertical-align: middle;
                    input{
                        outline: none;
                        vertical-align: middle;
                        &.site_input{
                            height: 25px;
                            width: 40px;
                            border: 1px solid #e1e1e1;
                            border-radius: 5px;
                            margin: 0 10px;
                        }
                        &.site_submit{
                            background-color: none;
                            border: none;
                            font-weight: 700;
                            padding-right: 30px;
                            background-repeat: no-repeat;
                            background-position: right center;
                            background-image: url('./../img/site_arr3.png');
                        }
                    }
                }
            }
        }
    }
}

.news_details{
    position: relative;
    z-index: 2;
    background: $white;
    padding-bottom: 40px;
    div{
        &.image{
            border-radius: 10px;
            width: 100%;
            height: 0;
            padding-bottom: 41%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            margin-bottom: 50px;
        }
        &.desc{
            .default_font{
                color: $font_color;
                h1,h2,h3,h4,h5,h6{
                    font-weight: 600;
                    color: $header_color;
                }
                h1,h2,h3,h4,h5,h6,p,ul,ol,p{
                    margin-bottom: 30px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .download, .galery{
        .section_header{
            padding-top: 50px;
            margin-top: 50px;
            border-top: 1px solid #d6dae1;
            @media only screen and (max-width: 991px) {
                padding-top: 30px;
                margin-bottom: 30px;
            }
        }
    }
    .download_list{
        ul{
            margin: 0 -15px;
            li{
                display: inline-block;
                vertical-align: top;
                padding: 0 15px;
                width: 100%;
                margin-bottom: 30px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                a{
                    text-decoration: none !important;
                    display: inline-block;
                }
                .download_box{
                    border-radius: 10px;
                    background-color: $white;
                    border: 1px solid #eaeaea;
                    border-bottom: 3px solid #d6dae1;
                    padding: 0 70px 0 20px;
                    background-repeat: no-repeat;
                    background-position: right 30px center;
                    background-image: url('./../img/d_icon2.png');
                    @include anim;
                    @media only screen and (max-width: 991px) {
                        padding: 0 20px;
                        background-image: none;
                    }
                    &:hover{
                        background-color: $accent_color;
                        background-image: url('./../img/d_icon2w.png');
                        div{
                            background-image: url('./../img/d_icon1w.png');
                            color: $white;
                        }
                    }
                    div{
                        height: 60px;
                        width: 100%;
                        line-height: 60px;
                        padding-left: 60px;
                        background-repeat: no-repeat;
                        background-position: left center;
                        background-image: url('./../img/d_icon1.png');
                        @include anim;
                        color: #000000;
                        p{
                            font-weight: 500;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 13.54px;

                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
    .gal_list{
        ul{
            margin: 0 -15px;
            text-align: center;
            li{
                padding: 0 15px;
                width: 16.66%;
                display: inline-block;
                vertical-align: top;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 30px;
                }
                .image{
                    width: 100%;
                    height: 0;
                    padding-bottom: 84%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    border-radius: 15px;
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 0;
                    }
                }
            }
        }

    }
}

.technical_break{
    position: relative;
    z-index: 2;
    background: $white;
    padding-bottom: 70px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 50px;
    }
    .section_header{
        margin-top: 30px;
        padding-top: 350px;
        background-position: center top;
        background-repeat: no-repeat;
        background-image: url('./../img/break_img.png');
        @media only screen and (max-width: 1500px) {
            background-size: 250px;
            padding-top: 250px;
        }
        .default_font{
            h2{
                color: $header_color;
            }
            font-size: 15px;
            color: #9da3ae;
        }
    }
}

.files_catalog{
    position: relative;
    background: $white;
    padding-top: 85px;
    .files_list{
        margin-bottom: 90px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 50px;
        }
        > ul{
            > li{
                margin-bottom: 30px;
                a{
                    display: block;
                }
                &.no_img{
                    .filee{
                        > div{
                            &.left_side{
                                > div{
                                    &.desc{
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
                &:last-child{
                    margin-bottom: 0;
                }
                .filee{
                    min-height: 177px;
                    display: flex;
                    border: 1px solid #e9edf2;
                    border-radius: 15px;
                    overflow: hidden;
                    -webkit-box-shadow: 0px 0px 28px 0px rgba(224,231,235,1);
                    -moz-box-shadow: 0px 0px 28px 0px rgba(224,231,235,1);
                    box-shadow: 0px 0px 28px 0px rgba(224,231,235,1);
                    @include anim;
                    @media only screen and (min-width: 991px) {
                        &:hover{
                            transform: scale(1.05);
                        }
                    }
                    @media only screen and (max-width: 991px) {
                        display: block;
                    }
                    > div{
                        &.left_side{
                            width: calc(100% - 170px);
                            display: flex;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                display: block;
                            }
                            > div{
                                &.image{
                                    width: 229px;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: cover;
                                    @media only screen and (max-width: 991px) {
                                        width: 100%;
                                        height: 0;
                                        padding-bottom: 66%;
                                    }
                                }
                                &.desc{
                                    width: calc(100% - 229px);
                                    font-size: 12px;
                                    line-height: 20px;
                                    color: #9da3ae;
                                    display: -ms-flexbox;
                                    display: -webkit-flex;
                                    display: flex;
                                    border-left: 1px solid #e9edf2;
                                    -ms-flex-align: center;
                                    -webkit-align-items: center;
                                    -webkit-box-align: center;

                                    align-items: center;
                                    @media only screen and (max-width: 991px) {
                                        display: block;
                                        width: 100%;
                                    }
                                    > div{
                                        padding: 0 40px 0 40px;
                                        width: 100%;
                                        @media only screen and (max-width: 991px) {
                                            padding: 30px 15px;
                                            text-align: center;
                                        }
                                    }
                                    .filee_header{
                                        color: #003a55;
                                        padding-bottom: 24px;
                                        margin-bottom: 25px;
                                        border-bottom: 1px solid #e9edf2;
                                        h4{
                                            font-size: 17px;
                                            line-height: 25px;
                                            font-weight: 600;
                                            span{
                                                color:#9da3ae;
                                                font-weight: 300;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.right_side{
                            background:#f8f9fa;
                            width: 170px;
                            display: -ms-flexbox;
                            display: -webkit-flex;
                            display: flex;
                            border-left: 1px solid #e9edf2;
                            -ms-flex-align: center;
                            -webkit-align-items: center;
                            -webkit-box-align: center;

                            align-items: center;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                height: 70px;
                            }
                            > div{
                                width: 100%;
                                text-align: center;
                                p{
                                    display: inline-block;
                                    font-size: 13px;
                                    line-height: 31px;
                                    padding-left: 35px;
                                    background-repeat: no-repeat;
                                    background-position: left center;
                                    background-image: url("./../img/green_download_icon.png");
                                    color:#8b939b;
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.glyphicon-pencil{
    cursor:pointer;
}

.skiplinks{
    position: absolute;
    left: 0;
    top: 0;
    ul{
        li{
            a{
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                white-space: nowrap;
                z-index: -1;
                &:focus{
                    opacity: 1;
                    z-index: 9999;
                }
            }
        }
    }
    a{
        background: rgba(0,0,0,0.8);
        color: $white;
        font-size: 16px;
        display: inline-block;
        padding: 5px 15px;
    }
}

.search_popup{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99999;
    background: rgba(255,255,255,0.8);
    display: none;
    > div{
        @include fill;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;

        align-items: center;
    }
    .search_box{
        padding:20px 30px 30px 30px;
        background: $white;
        -webkit-box-shadow: 0px 0px 28px 0px #e0e7eb;
        -moz-box-shadow: 0px 0px 28px 0px #e0e7eb;
        box-shadow: 0px 0px 28px 0px #e0e7eb;
        .form_header{
            position: relative;
        }
        .close_search{
            position: absolute;
            height: 41px;
            width: 41px;
            top: 0;
            right: 0;
            font-size: 28px;
            text-align: center;
            color: $accent_color;
            border: none;
            background: transparent;
            cursor: pointer;
        }
        label{
            color: $accent_color;
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 25px;

            padding-right: 50px;
            width: 100%;
        }
        form{
            width: 100%;

            input{
                height: 50px;
                border: none;
                &.search_text{
                    width: calc(100% - 150px);
                    padding: 0 20px;
                    font-size: 15px;
                    border: 1px solid #d7dde1;
                    border-right: none;
                }
                &.search_submit{
                    text-transform: uppercase;
                    color: $white;
                    background: $accent_color;
                    width: 150px;
                    font-size: 15px;
                    font-weight: 700;
                    border: 1px slid  $accent_color;;
                }
            }
        }
    }
}

*{
    &:focus{
        outline: 2px solid #afdb29 !important;
    }
}
a{
    &:focus{
        outline-offset: 2px;
    }
}

.news_list{
    a{
        &:focus{
          outline: none !important;
            article{
                outline: 2px solid #afdb29 !important;
            }
        }
    }
}

/* Increase the font size for the entire table */
.form-table {
    font-weight: normal !important;
    font-size: 14px; /* Adjust the font size as needed */
}

/* Increase the font size for table headings */
.form-table th {
    font-size: 13px; /* Adjust the font size as needed */
}

/* Increase the font size for table cells */
.form-table td {
    font-size: 12px; /* Adjust the font size as needed */
}

.check_list2{
    > ul{
        > li{
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

.radio-btn{
    border-radius: 50% !important;
    border: 1px solid #d5e5e9 !important;
    background-color: #eff3f5 !important;
    &:checked{

    }
}

.custom-radio input:checked + .radio-btn{
    background-color: #009cc9 !important;
}

.red_title{
    h2{
        color: red !important;
        strong{
            color: red !important;
        }
    }
}

.lb-nav{
    display: flex;
    .lb-prev{
        margin-right: auto;
        opacity: 0;
    }
    .lb-next{
        margin-left: auto;
        opacity: 0;
    }
}